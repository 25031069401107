import React from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Icon } from "../icons";

const StyledCard = styled.div`
  gap: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  h3 {
    margin: 20px 0 0;
  }

  /* TODO: Centralize this style */

  p {
    line-height: 1.3;
  }

  /* TODO: Centralize this style */

  .lenTime {
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: center;
    color: var(--slate);
    font-size: var(--fz-sm);
    font-weight: 500;

    & svg {
      width: 4px;
      height: 4px;
    }
  }
  /* TODO: Centralize this style */

  .read-more {
    border-radius: 40px;
    padding: 10px 20px;
    border: 1px solid var(--slate);
    background: transparent;
    color: var(--lightest-slate);
    transition: var(--transition);
    font-weight: 700;
    font-size: var(--fz-lg);

    &:hover {
      color: var(--dark-navy);
      background-color: var(--lightest-slate);
      border-color: var(--lightest-slate);
    }
  }
`;

const CardPost = ({ post }) => {
  const image = getImage(post?.featuredImage);
  return (
    <StyledCard>
      <GatsbyImage image={image} alt={post.title} />
      <h3>
        <Link className="no-highlight" to={`/blog/${post.slug}`}>
          {post.title}
        </Link>
      </h3>
      <p className="sub-text">
        {post.description?.childMarkdownRemark.excerpt}
      </p>
      <div className="lenTime">
        <span>{post?.fields.timeToRead} min read</span>
        <Icon name="Ellipse" />
        <span>{post?.publishedDate}</span>
      </div>
      <button
        className="read-more"
        onClick={() => navigate(`/blog/${post.slug}`)}
      >
        Read More
      </button>
    </StyledCard>
  );
};

export default CardPost;
