import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";
import styled from "styled-components";
import { navDelay } from "@utils";
import { usePrefersReducedMotion } from "@hooks";

import { Icon } from "../icons";

const StyledMainContainer = styled.main`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;

  svg {
    width: 200px;
    height: 200px;
  }
`;

const StyledSubtitle = styled.h2`
  font-size: clamp(15px, 5vw, 20px);
  font-weight: 400;
  line-height: 1;
  margin-top: 30px;
`;

const StyledHomeButton = styled(Link)`
  ${({ theme }) => theme.mixins.bigButton};
  margin-top: 40px;
`;

const NotFoundPage = ({ location }) => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const content = (
    <StyledMainContainer className="fillHeight">
      <Icon name="NoData" />
      <StyledSubtitle>No Data</StyledSubtitle>
      <StyledHomeButton to="/">Go Home</StyledHomeButton>
    </StyledMainContainer>
  );

  return (
    <TransitionGroup component={null}>
      {isMounted && (
        <CSSTransition timeout={500} classNames="fadeup">
          {content}
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default NotFoundPage;
