import styled from "styled-components";

export const Container = styled.div`
  display: grid;

  & > div:first-of-type {
    grid-area: 1/1;
    aspect-ratio: 3/1;
    opacity: 0.5;
    height: 100%;
    &::before {
      /* Create a pseudo-element to overlay the gradient */
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 50%; /* Adjust the width to control the gradient coverage */
      height: 100%;
      background: linear-gradient(
        to right,
        rgb(19, 19, 19) 32%,
        rgba(19, 19, 19, 0.9) 57%,
        rgba(19, 19, 19, 0.8) 70%,
        rgba(19, 19, 19, 0.7) 76%,
        rgba(19, 19, 19, 0.5) 85%,
        rgba(19, 19, 19, 0.3) 92%,
        rgba(19, 19, 19, 0.01) 99%
      );
      z-index: 1;

      @media (max-width: 768px) {
        width: 75%;
      }
    }
  }

  & > div:last-of-type {
    display: flex;
    grid-area: 1/1;
    flex-direction: column;
    justify-content: center;
    min-height: 500px;
    padding: 120px 150px 20px;
    gap: 10px;
    position: relative;
    z-index: 2;

    @media (max-width: 1080px) {
      padding: 120px 100px 0;
    }
    @media (max-width: 768px) {
      padding: 120px 50px 0;
    }
    @media (max-width: 480px) {
      padding: 20px 50px 20px;
    }
  }

  @media (max-width: 1080px) {
    padding: 120px 0 0;
  }
  @media (max-width: 768px) {
    padding: 120px 0 0;
  }
  @media (max-width: 480px) {
    padding: 120px 0px 0;
  }

  .featured {
    font-weight: 700;
    line-height: 1;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
  }

  .title {
    font-weight: 700;
    max-width: 550px;
  }

  .desc {
    max-width: 550px;
    font-weight: 400;
  }

  .read-more {
    border-radius: 40px;
    padding: 10px 20px;
    font-weight: 700;
    max-width: 300px;
    background: var(--lightest-slate);
    color: var(--dark-navy);
    border: 1px solid var(--lightest-slate);
    transition: var(--transition);
    margin-top: 10px;
    font-size: var(--fz-lg);

    &:hover {
      border-color: var(--slate);
      color: var(--lightest-slate);
      background: transparent;
    }
  }
`;
