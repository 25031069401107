import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Layout } from "@components";
import CardPost from "../components/CardPost";
import FeaturedPost from "../components/FeaturedPost";
import EmptyContent from "../components/EmptyContent";

const StyledMainContainer = styled.main`
  counter-reset: section;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  max-width: 1800px;

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 30px;
    padding: 0px;

    @media (max-width: 330px) {
      grid-template-columns: 1fr;
    }
  }
`;

const BlogPage = ({ location }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
          edges {
            node {
              title
              id
              slug
              featuredImage {
                gatsbyImageData(width: 250, height: 200, quality: 90)
              }
              publishedDate(formatString: "MMM D")
              description {
                childMarkdownRemark {
                  excerpt(pruneLength: 100)
                }
              }
              isFeatured
              fields {
                timeToRead
              }
            }
          }
        }
      }
    `
  );

  const posts = data.allContentfulBlogPost.edges;

  return (
    <Layout location={location}>
      <Helmet title="Blog" />

      <StyledMainContainer className="fillHeight">
        {posts.length > 0 ? (
          <>
            <FeaturedPost />
            <ul>
              {posts.map((edge) => {
                return <CardPost key={edge.node.id} post={edge.node} />;
              })}
            </ul>
          </>
        ) : (
          <EmptyContent />
        )}
      </StyledMainContainer>
    </Layout>
  );
};

BlogPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default BlogPage;
