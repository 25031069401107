import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link, graphql, useStaticQuery, navigate } from "gatsby";

import { Container } from "./styles";

const FeaturedPost = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulBlogPost(filter: { isFeatured: { eq: true } }) {
          edges {
            node {
              title
              id
              slug
              featuredImage {
                gatsbyImageData(
                  width: 1000
                  height: 600
                  quality: 50
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
              publishedDate(formatString: "MMM D")
              description {
                childMarkdownRemark {
                  excerpt(pruneLength: 150)
                }
              }
              isFeatured
            }
          }
        }
      }
    `
  );

  const post = data.allContentfulBlogPost.edges[0]?.node;

  if (!post) return null;

  return (
    <Container className="fillWidth">
      {post.featuredImage && (
        <GatsbyImage
          image={post.featuredImage.gatsbyImageData}
          alt={post.title}
        />
      )}
      <div>
        <span className="featured">Featured Article</span>
        <h1 className="title">
          <Link className="no-highlight" to={`/blog/${post.slug}`}>
            {post.title}
          </Link>
        </h1>
        {post.description && (
          <p className="desc">{post.description.childMarkdownRemark.excerpt}</p>
        )}
        <button
          className="read-more"
          onClick={() => navigate(`/blog/${post.slug}`)}
        >
          Read Full Article
        </button>
      </div>
    </Container>
  );
};

export default FeaturedPost;
